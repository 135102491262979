// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-affordability-calculator-tsx": () => import("./../../../src/pages/affordability-calculator.tsx" /* webpackChunkName: "component---src-pages-affordability-calculator-tsx" */),
  "component---src-pages-amortization-calculator-tsx": () => import("./../../../src/pages/amortization-calculator.tsx" /* webpackChunkName: "component---src-pages-amortization-calculator-tsx" */),
  "component---src-pages-basics-tsx": () => import("./../../../src/pages/basics.tsx" /* webpackChunkName: "component---src-pages-basics-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mortgage-calculator-tsx": () => import("./../../../src/pages/mortgage-calculator.tsx" /* webpackChunkName: "component---src-pages-mortgage-calculator-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */)
}

